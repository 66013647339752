#ProjectsGrid {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 40px;
    background-color: #0e0e0e;
    align-items: center; /* centra las cards */
  }
  
  .ModernCard {
    background-color: #1a1a1a;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    border: 1px solid #2c2c2c;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 1000px;
    width: 100%;
    height: 280px; /* altura fija para igualar */
  }
  
  .ModernCard:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0, 255, 255, 0.1);
  }
  
  /* Imagen fija a la izquierda */
  .image-wrapper {
    width: 300px;
    height: 100%;
    background-color: #111;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .img-skeleton {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #222 25%, #333 50%, #222 75%);
    animation: skeleton 1.2s infinite ease-in-out;
  }
  
  @keyframes skeleton {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
  
  .CardContent {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    color: #ffffffcc;
  }
  
  .CardContent h2 {
    font-size: 1.5rem;
    color: #fff;
    margin: 0 0 10px 0;
    font-weight: bold;
  }
  
  .CardContent p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
    max-height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .pill-button {
    align-self: flex-start;
    background-color: #00ffff10;
    border: 1px solid #00ffff;
    color: #00ffff;
    padding: 8px 20px;
    border-radius: 40px;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.95rem;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .pill-button:hover {
    background-color: #00ffff;
    color: #000;
  }
  
  /* Mobile: se acomoda en vertical */
  @media (max-width: 768px) {
    .ModernCard {
      flex-direction: column;
      height: auto;
    }
  
    .image-wrapper {
      width: 100%;
      height: 220px;
    }
  
    .CardContent {
      padding: 16px;
    }
  }
  