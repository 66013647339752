
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

.App {
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
    background-image: url('./images/CODEBG.png');
    background-attachment: fixed; /* Mantén el fondo fijo */
    background-size: 60%; /* Escala la imagen para que se vea más expandida */
    background-repeat: repeat-y repeat-x; /* Repite solo en el eje Y */
    background-position: center top; /* Centra el fondo horizontalmente */
}

@media (max-width: 768px) {
    .App {
        background-attachment: scroll; /* Elimina el efecto de fondo fijo */
        background-size: 200%; /* Reduce el tamaño relativo */
        background-repeat: repeat; /* Permite que se repita */
        background-position: center top; /* Ajusta la posición */
    }
}






