/* Footer.css */
.footer {
    background-color: #000;
    color: #fff;
    padding: 20px 10px;
    text-align: center;
  }
  .Firma{
    position: absolute;
    
    
    
    

  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    height: 300px;
    
    

  }
  
  .footer-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .social-link {
    color: #fff;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .social-link:hover {
    color: #1da1f2; /* Color destacado al pasar el cursor */
    transform: scale(1.1);
  }
  
  .social-link svg {
    width: 30px;
    height: 30px;
  }
  
  @media (max-width: 768px) {
    .footer-title {
      font-size: 1.2rem;
    }
  
    .social-link svg {
      width: 25px;
      height: 25px;
    }
  }
  
  @media (max-width: 480px) {
    .social-icons {
      gap: 10px;
    }
  
    .social-link svg {
      width: 20px;
      height: 20px;
    }
  }
  