#ReelsSection {
    padding: 100px 40px; /* 👈 Más alto */
    background: rgba(0, 0, 0, 0.8); /* 👈 Negro con transparencia */
    color: white;
    overflow-x: hidden;
  }
  
  .reels-title {
    color: #00ffff;
    font-size: 2.2rem;
    margin-bottom: 20px;
    font-family: 'Orbitron', sans-serif;
  }
  
  .reels-scroll {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-bottom: 10px;
    scrollbar-width: thin;
    scrollbar-color: #00ffff #111;
  }
  
  .reels-scroll::-webkit-scrollbar {
    height: 8px;
  }
  
  .reels-scroll::-webkit-scrollbar-thumb {
    background: #00ffff;
    border-radius: 10px;
  }
  
  .reel-card-horizontal {
    flex: 0 0 340px;
    scroll-snap-align: start;
    background-color: #111;
    border-radius: 14px;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 255, 255, 0.1);
    transition: transform 0.3s ease;
  }
  
  .reel-card-horizontal:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 20px rgba(0, 255, 255, 0.15);
  }
  
  .reel-card-horizontal iframe {
    width: 100%;
    height: 220px;
    border-radius: 10px;
    border: none;
  }
  