#Posts {
    margin: 40px 60px; /* Alineado a la izquierda */
    padding: 40px 20px;
    background: linear-gradient(to bottom, rgba(0, 1, 17, 0.9), rgb(0, 0, 0));
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(248, 248, 248, 0.08);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .Textmain {
    color: #00ffff;
    font-size: 1.8rem;
    margin-bottom: 30px;
    font-family: 'Orbitron', sans-serif;
    text-align: left;
    width: 100%;
  }
  
  .post {
    width: 100%;
    max-width: 800px;
    margin-bottom: 40px;
    padding: 20px;
    background-color: #111;
    border-radius: 12px;
    border: 1px solid #1c1c1c;
    box-shadow: 0 2px 10px rgba(0, 255, 255, 0.05);
    transition: all 0.3s ease;
  }
  
  .post:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(0, 255, 255, 0.1);
  }
  
  .post h3 {
    font-family: 'Orbitron', sans-serif;
    font-size: 1.4rem;
    color: #ccf07a;
    margin-bottom: 10px;
  }
  
  .post p {
    font-size: 1rem;
    color: #ddd;
    line-height: 1.5;
  }
  
  .post small {
    display: block;
    margin-top: 10px;
    color: #888;
    font-size: 0.9rem;
  }
  
  .video {
    margin-top: 20px;
  }
  
  .video iframe {
    width: 100%;
    height: 400px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 255, 255, 0.1);
    border: none;
  }
  
  .see-more-button {
    align-self: flex-start;
    padding: 12px 28px;
    background-color: transparent;
    color: #00ffff;
    border: 2px solid #00ffff;
    border-radius: 30px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 10px;
  }
  
  .see-more-button:hover {
    background-color: #00ffff;
    color: #000;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    #Posts {
      margin: 20px;
      padding: 20px;
    }
  
    .Textmain {
      font-size: 1.4rem;
    }
  
    .post {
      padding: 15px;
    }
  
    .video iframe {
      height: 250px;
    }
  }
  