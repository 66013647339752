.about-section {
    width: 100%;
    padding: 100px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
  }
  
  /* Animación entrada suave */
  @keyframes floatIn {
    0% {
      opacity: 0;
      transform: translateY(30px) scale(0.98);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
  
  .about-container {
    max-width: 900px;
    background: rgba(17, 17, 17, 0.9);
    padding: 40px;
    border-radius: 16px;
    border: 1px solid rgba(0, 255, 255, 0.15);
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.05);
    color: #d6f6ff;
    font-family: 'PT Sans Caption', sans-serif;
    line-height: 1.6;
    font-size: 1.1rem;
    animation: floatIn 1.2s ease forwards;
    opacity: 0;
    transform: translateY(20px);
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    transition: transform 0.5s ease;
  }
  
  /* Efecto pulse */
  .about-container:hover {
    transform: scale(1.015) translateY(-2px);
    box-shadow: 0 0 30px rgba(0, 255, 255, 0.15);
  }
  
  .about-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #00ffff;
    font-weight: bold;
    text-align: center;
    text-shadow: 0 0 4px rgba(0, 255, 255, 0.2);
  }
  
  .about-container strong {
    color: #ffffff;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .about-container {
      padding: 30px 20px;
      font-size: 1rem;
    }
  
    .about-container h2 {
      font-size: 1.5rem;
    }
  }
  .skills-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  
  .skills-icons img {
    width: 48px;
    height: 48px;
    transition: transform 0.3s ease;
    filter: drop-shadow(0 0 3px rgba(0, 255, 255, 0.4));
  }
  
  .skills-icons img:hover {
    transform: scale(1.15);
    filter: drop-shadow(0 0 8px rgba(0, 255, 255, 0.9));
  }
  
  