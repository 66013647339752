.Divmain{
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    width:100vw ;
    background: linear-gradient(to bottom, rgba(0, 3, 43, 0.8), rgb(0, 0, 0));
    
}

.ContentNav{
    display: flex;
    width: 200px;
    height: 400px;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    

}

.btn{
    border: 0px;
    border-radius: 10px;
    font-size: 25px;
    background-color: transparent;
    color: aliceblue;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 5px;
    font-family: 'PT Sans Caption', sans-serif;
      
    

}
.btn:hover{

    background-color: aliceblue;
    transition: all 0.3s ease;
    color: black;
    transform: scale(1.1);
    border-radius: 10px;

}
.btn:active {
    transform: scale(0.95); /* Hace que el botón se encoja al hacer clic */
    background-color: #15e4ff; /* Cambio de color */
  }

.logo{
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 5%;
    transition: all 0.3s ease;
    
}
.logo:hover{
    
    transition: all 0.3s ease;
    border: 1px solid rgb(255, 255, 255);
    border-bottom: 10px solid white;
    border-radius: 10px;
    padding: 5%;
    
    


}